import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import tw from "twin.macro";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export default function contact() {
  const formik = useFormik({
    initialValues: {
      name: "",
      street: "", // spam protection 2: honeypot check in backend
      town: "", // spam protection 1: honeypot for stupid bots
      email: "",
      subject: "",
      message: "",
      privacyCheck: false,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Muss mindestens 3 Zeichen enthalten")
        .max(30, "Darf nicht mehr als 30 Zeichen enthalten")
        .required("Pflichtfeld"),
      email: Yup.string()
        .email("Keine gültige E-Mail-Adresse")
        .required("Pflichtfeld"),
      subject: Yup.string()
        .min(1, "Muss mindestens 1 Zeichen enthalten")
        .max(50, "Darf nicht mehr als 50 Zeichen enthalten"),
      message: Yup.string()
        .min(10, "Muss mindestens 10 Zeichen enthalten")
        .max(500, "Darf nicht mehr als 500 Zeichen enthalten")
        .required("Pflichtfeld"),
      privacyCheck: Yup.bool().oneOf([true], "Pflichtfeld"),
    }),
    // https://formik.org/docs/api/formik#onsubmit-values-values-formikbag-formikbag--void--promiseany
    // https://stackoverflow.com/questions/51099439/reactjs-using-formik-to-write-a-form-with-axios
    // https://github.com/kilinkis/react-formik-yup-axios-typescript/blob/master/src/index.tsx
    onSubmit: async (values, { resetForm, setSubmitting, setStatus }) => {
      //alert(JSON.stringify(values, null, 2));

      // spam protection 1
      if (values.town !== "") {
        return;
      }

      try {
        const response = await axios.post("/api/sendmail", values);
        setStatus({ success: true });
        //console.log(response.data.result);
      } catch (error) {
        setStatus({ success: false });
        //console.error(error);
      }

      setSubmitting(false);

      setTimeout(() => {
        resetForm();
      }, 5000);
    },
  });

  return (
    <Layout>
      <SEO title="Kontakt" />

      <main>
        <title>Kontakt</title>
      </main>
      {/* Landing section starts here */}
      <section>
        <StaticImage src="../images/Kontakt_Wuerfel.jpg" />
      </section>
      {/* Landing Section ends here */}
      {/* Contact section starts here */}
      <section tw="md:grid  md:grid-cols-5 px-6 md:px-12 my-6 text-black">
        {/* first grid */}
        <div className="" tw="md:col-start-1 md:col-end-3">
          <div className="fusion-text">
            <div
              className="fusion-text"
              tw="mt-4 text-lg leading-7 text-black sm:mt-3"
            >
              <p>
                REALDESIGN <span> |</span> TEAM
              </p>
              <p>Gerhart-Hauptmann Str. 5</p>
              <p>33613 Bielefeld</p>
            </div>
            <div tw="mt-4 text-lg leading-7 text-black mb-4  sm:mt-3">
              <p>
                Telephone: <a href="tel:+4952132725140">0521/32725140</a>
              </p>
              <p>Email: info (at) realdesignteam.de</p>
              <span>
                <p>Web: </p>
                <a href="https://realdesignteam.de">www.realdesignteam.de</a>
              </span>
              <p tw="pt-4 invisible md:visible w-1/2 h-auto">
                <StaticImage src="../../images/RD_Logo_Rot_Retina.png" alt="" />
              </p>
            </div>
          </div>
        </div>
        {/* First grid ends here */}
        {/* Second grid starts here */}
        <div tw="md:col-start-3 col-end-6">
          <div className="top">
            <h6 tw="text-2xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
              <span>Sie haben generelle Fragen zum Unternehmen</span>
              <br />
              <span>
                REALDESIGN | TEAM, unseren Produkten oder einer Kampagne?
              </span>
              <p tw="text-primary pt-6">
                <span>Senden sie uns eine E-mail</span>
              </p>
            </h6>
          </div>
          <div className="form">
            <form
              onSubmit={formik.handleSubmit}
              tw="mt-9 grid grid-cols-1 row-gap-6  sm:col-gap-8"
            >
              {/* text input */}
              <div tw="sm:col-span-2">
                <label
                  htmlFor="Name"
                  tw="block text-base font-medium leading-5 text-black"
                >
                  Ihr Name
                </label>
                <div tw="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="name"
                    type="text"
                    tw=" block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-full py-3 px-6"
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div tw="text-primary">{formik.errors.name}</div>
                  ) : null}
                </div>
              </div>
              {/* text input */}
              <div tw="sm:col-span-2">
                <label
                  htmlFor="email"
                  tw="block text-base  font-medium leading-5 text-black"
                >
                  Ihre E-Mail-Adresse
                </label>
                <div tw="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="email"
                    type="email"
                    tw=" block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-full py-3 px-6"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div tw="text-primary">{formik.errors.email}</div>
                  ) : null}
                </div>
              </div>
              {/* spam protect */}
              <div tw="mt-4 hidden">
                <label>
                  <span tw="font-medium mb-1 inline-block">Strasse</span>
                  <br />
                  <input
                    id="street"
                    type="text"
                    {...formik.getFieldProps("street")}
                  />
                </label>
              </div>
              <div tw="mt-4 hidden">
                <label>
                  <span tw="font-medium mb-1 inline-block">Stadt</span>
                  <br />
                  <input
                    id="town"
                    type="text"
                    {...formik.getFieldProps("town")}
                  />
                </label>
              </div>
              {/* text input */}
              <div tw="sm:col-span-2">
                <label
                  htmlFor="Subject"
                  tw="block text-base  font-medium leading-5 text-black"
                >
                  Betreff
                </label>
                <div tw="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="subject"
                    type="text"
                    tw=" block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-full py-3 px-6"
                    {...formik.getFieldProps("subject")}
                  />
                  {formik.touched.subject && formik.errors.subject ? (
                    <div tw="text-primary">{formik.errors.subject}</div>
                  ) : null}
                </div>
              </div>
              {/* text area */}
              <div tw="sm:col-span-2">
                <label
                  htmlFor="how_can_we_help"
                  tw="block text-base  font-medium leading-5 text-black"
                >
                  Ihre Nachricht an uns
                </label>

                <div tw="mt-1 relative rounded-md shadow-sm">
                  <textarea
                    id="message"
                    rows="4"
                    tw=" block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-4xl py-3 px-6"
                    {...formik.getFieldProps("message")}
                  />
                  {formik.touched.message && formik.errors.message ? (
                    <div tw="text-primary">{formik.errors.message}</div>
                  ) : null}
                </div>
              </div>
              {/* Privacy check */}
              <div tw="sm:col-span-2">
                <label>
                  <span tw="font-medium mb-1 inline-block">
                    <Link
                      tw="hover:text-primary hover:underline"
                      to="/dataprotection"
                    >
                      Datenschutzhinweise
                    </Link>{" "}
                    gelesen und zustimmen:
                  </span>
                  <input
                    id="privacyCheck"
                    type="checkbox"
                    checked={formik.values.privacyCheck}
                    tw="ml-2 border-2 border-gray-300 rounded-md focus:border-primary focus:ring-primary focus:ring-opacity-25 focus:ring-4"
                    {...formik.getFieldProps("privacyCheck")}
                  />
                  {formik.touched.privacyCheck && formik.errors.privacyCheck ? (
                    <div tw="text-primary">{formik.errors.privacyCheck}</div>
                  ) : null}
                </label>
              </div>
              <div tw="text-left sm:col-span-2">
                <span>
                  <button
                    disabled={
                      formik.isSubmitting ||
                      formik.status?.success !== undefined
                    }
                    type="submit"
                    css={[
                      tw`inline-flex justify-center px-8 py-3 text-base font-medium leading-5 tracking-widest text-white transition duration-150 border border-transparent rounded-full`,
                      !formik.isSubmitting &&
                        formik.status?.success === undefined &&
                        formik.isValid &&
                        tw`ease-in-out bg-primary hover:bg-red-500 focus:outline-none focus:border-red-700 active:bg-red-700`,
                      (formik.isSubmitting || !formik.isValid) &&
                        tw`bg-gray-700 cursor-not-allowed`,
                      formik.status?.success &&
                        tw`bg-green-400 cursor-not-allowed`,
                      formik.status?.success === false &&
                        tw`cursor-not-allowed`,
                    ]}
                  >
                    {!formik.isSubmitting &&
                      formik.status?.success === undefined &&
                      "SENDEN"}
                    {formik.isSubmitting && "Sende ..."}
                    {formik.status?.success && "Erfolgreich versendet"}
                    {formik.status?.success === false && "Fehler beim Senden"}
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
        {/* Second grid ends here */}
      </section>
      {/* Contact section ends in here */}
      
      {/* test end */}
    </Layout>
  );
}
